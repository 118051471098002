import { render, staticRenderFns } from "./aditionalPurchaseInfoDetail.vue?vue&type=template&id=49ec6686&scoped=true"
import script from "./aditionalPurchaseInfoDetail.vue?vue&type=script&lang=js"
export * from "./aditionalPurchaseInfoDetail.vue?vue&type=script&lang=js"
import style0 from "./aditionalPurchaseInfoDetail.vue?vue&type=style&index=0&id=49ec6686&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ec6686",
  null
  
)

export default component.exports