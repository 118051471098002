<template>
  <div class="time-line-container">
    <ul class="time-line-list">
      <li v-for="(itemL, l) in data" :key="l + 'l'" class="time-line-item">
        <div class="flex time-header">
          <span class="time-header-l">{{ itemL.time }}</span>
          <span class="time-header-r">{{ itemL.statusName }}</span>
        </div>
        <ul class="time-line-content">
          <slot name="content" :data="itemL" />
          <!-- <li v-for="(itemC, c) in itemL.contents" :key="c + 'c'">{{ itemC.text }}</li> -->
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.time-line-container {
  .time-line-list {
    .time-line-item {
      position: relative;
      padding: 0 24px 24px 24px;
      border-left: 1px solid #ededed;
      &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: -4px;
        width: 8px;
        height: 8px;
        background-color: #2861e2;
        border-radius: 50%;
      }
      .time-header {
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        .time-header-l {
          font-size: 14px;
          color: #9a9d9e;
        }
        .time-header-r {
          color: #000000;
          font-size: 14px;
        }
      }
      .time-line-content {
        min-height: 40px;
        border-bottom: 1px solid #ededed;
        color: #000000;
        font-size: 14px;
        li {
          margin: 8px 0;
        }
      }
    }
  }
}
</style>
