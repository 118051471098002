<template>
  <div class="aditional-order-record">
    <TimeLine class="server-record-time-line" :data="activities">
      <template slot="content" slot-scope="scope">
        <template v-if="scope.data.status == 3">
          <div class="content-item">退款产品：{{ scope.data.productName }}</div>
          <div class="content-item">退款金额：￥{{ scope.data.amount }}</div>
        </template>
        <!-- || scope.data.status == 3 -->
        <div v-if="scope.data.status == 0 || scope.data.status == 1" class="content-item">
          操作人：{{ scope.data.createUserName }}
        </div>
        <div v-if="scope.data.status == 1" class="content-item">
          取消原因：{{ scope.data.reason }}
        </div>
      </template>
    </TimeLine>
  </div>
</template>

<script>
import TimeLine from '@/components/TimeLine'
export default {
  components: {
    TimeLine,
  },
  props: {
    orderReords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activities: [],
    }
  },
  watch: {
    orderReords: {
      immediate: true,
      deep: true,
      handler(value) {
        this.activities = value.map((item) => {
          item.time = item.createTime
          if (item.status === 0) {
            item.statusName = '创建订单'
          } else if (item.status === 1) {
            item.statusName = '取消订单'
          } else if (item.status === 2) {
            item.statusName = '支付成功'
          } else if (item.status === 3) {
            item.statusName = '退款成功'
          } else if (item.status === 4) {
            item.statusName = '已关闭'
          } else if (item.status === 5) {
            item.statusName = '已完成'
          }
          return item
        })
      },
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.aditional-order-record {
  padding: 24px;
  .content-item {
    margin-bottom: 6px;
  }
}
</style>
