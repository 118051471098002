<template>
  <div class="aditional-basic-info">
    <div class="flex aditional-purchase-info-operate">
      <div class="operate-l">产品信息</div>
    </div>
    <div class="order-list-container">
      <ul v-for="(item, i) in addProductInfos" :key="i" class="order-list">
        <li class="list-item">
          <div class="flex item-content">
            <div style="flex: 1">
              <div class="item-common item-content-img">
                <div
                  v-for="(itemS, s) in item.productListDtoList"
                  :key="s + 's'"
                  class="item-border"
                >
                  <ul class="flex item-content-img-item">
                    <el-image class="item-content-img-l" :src="itemS.coverPictureUrl" alt="">
                      <span slot="error" class="el-image-error">暂无图片</span>
                    </el-image>
                    <li class="item-content-img-r">
                      <div class="flex img-r-info">
                        <span class="img-r-info-title">{{ itemS.productName }}</span>
                        <span class="img-r-info-text">￥{{ itemS.salePrice }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span class="img-r-info-text"></span>
                        <span class="img-r-info-text">x{{ itemS.number }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span
                          v-if="itemS.baseProductType == 9 || itemS.baseProductType == 16"
                          class="img-r-info-text"
                          >相纸页数：{{ itemS.designNum }}</span
                        >
                        <span class="img-r-info-text">规格：{{ itemS.specification }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span
                          v-if="itemS.baseProductType == 9 || itemS.baseProductType == 16"
                          class="img-r-info-text"
                          >照片张数：{{ itemS.photos }}</span
                        >
                        <span class="img-r-info-text">计费单位：{{ itemS.priceUnit }}</span>
                      </div>
                    </li>
                  </ul>
                  <div v-if="itemS.dtoList && itemS.dtoList.length > 0">
                    <ul
                      v-for="(n, index) in itemS.dtoList"
                      :key="index"
                      class="flex item-content-img-item"
                    >
                      <el-image class="item-content-img-l" :src="n.coverPictureUrl" alt="">
                        <span slot="error" class="el-image-error">暂无图片</span>
                      </el-image>
                      <li class="item-content-img-r">
                        <div class="flex img-r-info">
                          <span class="img-r-info-title">{{ n.productName }}</span>
                          <span class="img-r-info-text">￥{{ n.salePrice }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text"></span>
                          <span class="img-r-info-text">x{{ n.number }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text">规格：{{ n.specification }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text">计费单位：{{ n.priceUnit }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-if="itemS.refundStatus == 1" class="footer">申请退款</div>
                  <div v-if="itemS.refundStatus == 3" class="footer">
                    已退款:￥{{ itemS.refundAmount }}
                  </div>
                  <div class="footer">
                    <el-button
                      v-if="
                        itemS.refundStatus &&
                        menus.find((e) => e.nodeCode == 'GY_ORDER_POSTSALE_DETAIL')
                      "
                      class="default"
                      type="primary"
                      size="mini"
                      @click="toAfterSaleDetail(itemS)"
                    >
                      售后详情
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-common item-money">
              <div class="item-lable" style="font-size: 18px">实付：￥{{ item.payAmount }}</div>
              <div class="item-text">合计：￥{{ item.payAmount }}</div>
              <div class="item-text">优惠：￥{{ item.discountAmount }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-descriptions title="支付信息" :column="1" border :label-style="{ width: '120px' }">
      <el-descriptions-item label="付款方式">{{ orderDetailInfo.payWayName }}</el-descriptions-item>
      <el-descriptions-item label="实付金额"
        >￥{{ orderDetailInfo.payAmount || '0.00' }}</el-descriptions-item
      >
      <el-descriptions-item label="退款金额"
        >￥{{ orderDetailInfo.refundAmount || '0.00' }}</el-descriptions-item
      >
      <el-descriptions-item label="总金额"
        >￥{{ calculateAllMoney || '0.00' }}</el-descriptions-item
      >
    </el-descriptions>
    <el-descriptions
      v-if="orderDetailInfo.managerName"
      title="销售顾问"
      :column="1"
      border
      :label-style="{ width: '120px' }"
    >
      <el-descriptions-item label="销售顾问">{{
        orderDetailInfo.managerName
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: {
    orderDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    addProductInfos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    calculateAllMoney() {
      return (
        Number(this.orderDetailInfo.payAmount) - Number(this.orderDetailInfo.refundAmount)
      ).toFixed(2)
    },
  },
  mounted() {
    this.menus = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      ? JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      : []
  },
  methods: {
    toAfterSaleDetail(item) {
      this.$router.push({
        name: 'afterSaleDetails',
        query: { orderServiceId: item.orderServiceId, orderId: this.$parent.orderId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  text-align: right;
  color: #e86247;
  padding: 0 16px;
  padding-bottom: 10px;
}
.aditional-basic-info {
  padding: 22px 40% 20px 20px;
  .default {
    background-color: #fff;
    color: #9a9d9e;
    border: 1px solid #ededed;
    &:hover {
      opacity: 0.8;
    }
  }
  .aditional-purchase-info-operate {
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .operate-l {
      font-weight: 500;
      font-size: 14px;
    }

    .refuse {
      background-color: #fff;
      color: #2861e2;
      border: 1px solid #2861e2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .order-list-container {
    background-color: #fff;
    font-size: 14px;
    .order-list {
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;

      .list-item {
        .item-content {
          .item-border {
            border-bottom: 1px solid #e0e0e0;
          }
          .item-border:last-child {
            border: none;
          }
          .item-common {
            flex: 1;
            padding: 16px;
            border-right: 1px solid #e0e0e0;
          }

          .item-lable {
            margin-bottom: 12px;
            font-weight: 500;
          }
          .item-text {
            margin-bottom: 12px;
          }
          .item-img-text {
            margin-bottom: 12px;
            color: #666666;
          }
          .item-content-img {
            padding: 0;
            .item-content-img-item {
              padding: 20px;
              // border-bottom: 1px solid #e0e0e0;
              .item-content-img-l {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                vertical-align: middle;
              }
              .item-content-img-r {
                width: 100%;
                .img-r-info {
                  justify-content: space-between;
                  .img-r-info-title {
                    font-weight: 500;
                  }
                  .img-r-info-text {
                    margin-bottom: 4px;
                  }
                }
              }
              &:last-child {
                border: none;
              }
            }
          }
          .item-money {
            max-width: 220px;
            border: none;
          }
        }
      }
    }
    .pagination {
      padding: 20px 0 24px 0;
      text-align: right;
    }
  }
}
</style>
